import request from "../utils/request";

export const put = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "create/put",
        method: "post",
        data: query,
    });
};


export const historyput = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "create/history",
        method: "post",
        data: query,
    });
};


export const historyfind = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "create/history/find",
        method: "post",
        data: query,
    });
};



